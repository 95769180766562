


































































































































import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { formatFileSize } from "@/utils/helpers";
import { mapActions } from "vuex";

const defaultData = {
    fileName: "",
    version: "",
    size: "",
    docUploadId: "",
};
export default Vue.extend({
    name: "UploadDocumentModal",
    inheritAttrs: false,
    data() {
        return {
            loading: false,
            isSaving: false,
            form: {
                ...defaultData,
            },
            file: {},
        };
    },
    validations(): any {
        return {
            form: {
                fileName: {
                    required,
                },
                version: {
                    required,
                },
                size: {
                    required,
                },
                docUploadId: {
                    required,
                },
            },
        };
    },
    methods: {
        ...mapActions({
            uploadFile: "file/uploadFile",
            fetchFile: "file/fetchFile",
            deleteFile: "file/removeFile",
        }),
        async processFileUpload(file: any) {
            this.loading = true;
            const formData = new FormData();
            formData.append("file", file);
            try {
                const response = await this.uploadFile(formData);
                this.$bvToast.toast("", {
                    title: "File Upload successful!",
                    variant: "success",
                    solid: true,
                });
                this.loading = false;
                return response;
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to upload file!",
                    variant: "error",
                    solid: true,
                });
            }
            this.loading = false;
            return null;
        },
        formatFileSize,
        saveDocument() {
            const payload = { ...this.form };
            this.form = { ...defaultData };
            this.file = {};
            this.$emit("completed", payload);
        },
        openFilePicker() {
            (this.$refs.filePicker as HTMLDivElement).click();
        },
        async onFileChange(e: any): Promise<void> {
            const [file] = e.target.files;
            this.file = file;
            const response = await this.processFileUpload(file);
            this.form.docUploadId = response.fileName;
            this.form.size = response.size;
        },
        async removeFile(): Promise<void> {
            try {
                this.loading = true;
                await this.deleteFile(this.form.docUploadId);
                this.file = {};
                this.form.docUploadId = "";
                this.loading = false;

                // (this.$refs.filePicker as HTMLInputElement).value = "";
                this.$emit("resetIdentity");
            } catch (e) {
                console.log(e);
                this.$bvToast.toast(
                    "Something went wrong - this will be corrected",
                    {
                        title: "Error!",
                        variant: "danger",
                        solid: true,
                    },
                );
            }
        },
        async viewFile() {
            try {
                const { presignedUrl } = await this.fetchFile(
                    this.form.docUploadId,
                );
                window.open(presignedUrl, "_blank");
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "error",
                    solid: true,
                });
            }
        },
    },
});
